<template>
  <RevContainer
    id="toast-wrapper"
    class="pb-32 md:pb-36"
    data-test="refer-a-friend-page"
  >
    <div class="flex flex-col gap-40">
      <template v-if="data?.hasPromoCode">
        <MaxReferralsReachedBanner
          v-if="data.myReferrals.referralLimitReached"
        />
        <WithReferralView
          v-if="data.referralCode"
          v-bind="data"
          @promo-code-generated="refresh"
        />
      </template>

      <NoReferralCodeBlock v-else />
    </div>

    <RevDivider class="mb-40 mt-56 grow" />

    <HowDoReferralsWorkBlock />
  </RevContainer>
</template>

<script lang="ts" setup>
import { createError } from '#imports'

import { RevContainer } from '@ds/components/Container'
import { RevDivider } from '@ds/components/Divider'

import HowDoReferralsWorkBlock from './components/HowDoReferralsWorkBlock.vue'
import MaxReferralsReachedBanner from './components/MaxReferralsReachedBanner.vue'
import NoReferralCodeBlock from './components/NoReferralCodeBlock.vue'
import WithReferralView from './components/WithReferralView.vue'
import { useReferAFriend } from './useReferAFriend'

const { data, error, refresh } = await useReferAFriend()
if (error.value) {
  throw createError({
    ...error.value,
    fatal: true,
  })
}
</script>
