<template>
  <RevButton
    class="md:hidden"
    full-width="always"
    variant="primary"
    @click="handleClick"
  >
    {{ i18n(translations.shareButtonLabel) }}
  </RevButton>
</template>

<script lang="ts" setup>
import type { MonetaryAmount } from '@backmarket/http-api'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevButton } from '@ds/components/Button'

import translations from '../ReferAFriend.translations'
import { REFER_FRIEND_TRACKING } from '../tracking.constants'

const props = defineProps<{ referralCode: string; amount: MonetaryAmount }>()

const { trackClick } = useTracking()

const logger = useLogger()
const i18n = useI18n()

const hasProp = <Prop extends string>(
  obj: unknown,
  prop: Prop,
): obj is Record<Prop, unknown> =>
  !!obj && typeof obj === 'object' && prop in obj

const handleClick = async () => {
  try {
    const shareText = [
      i18n(translations.shareButtonTextStart, {
        amount: i18n.price(props.amount),
      }),
      props.referralCode,
      i18n(translations.shareButtonTextEnd),
    ].join(' ')

    await navigator.share({
      title: i18n(translations.shareButtonTitle),
      text: shareText,
    })

    trackClick({
      page: REFER_FRIEND_TRACKING.PAGE,
      name: 'share_link',
      zone: 'has_refer_code',
    })
  } catch (error) {
    const message = `[LIF][Refer_friend][Share] ${hasProp(error, 'message') ? error.message : ''}`
    logger.error(message, {
      error: error as Error,
      owners: ['bot-squad-lifecycle-front'],
    })
  }
}
</script>
