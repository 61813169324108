<template>
  <div class="flex flex-col gap-24 md:gap-28">
    <h3 class="heading-3">
      {{ i18n(translations.title) }}
    </h3>

    <div
      class="flex flex-col gap-24 md:grid-standard md:mb-0 md:grid-cols-3 md:gap-28"
    >
      <RevInfoBlock
        v-for="{ icon, title, content } in infoBlocks"
        :key="title"
        :content
        :icon
        :title
        variant="info"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevInfoBlock } from '@ds/components/InfoBlock'
import { IconGift } from '@ds/icons/IconGift'
import { IconMegaphone } from '@ds/icons/IconMegaphone'
import { IconPromo } from '@ds/icons/IconPromo'

import translations from './HowDoReferralsWorkBlock.translations'

const i18n = useI18n()

const infoBlocks = [
  {
    icon: IconMegaphone,
    title: i18n(translations.titleStep1),
    content: i18n(translations.contentStep1),
  },
  {
    icon: IconPromo,
    title: i18n(translations.titleStep2),
    content: i18n(translations.contentStep2),
  },
  {
    icon: IconGift,
    title: i18n(translations.titleStep3),
    content: i18n(translations.contentStep3),
  },
]
</script>
