import { useAsyncData } from '#app'

import type { HttpApiError } from '@backmarket/http-api'
import { getReferral } from '@backmarket/http-api/src/api-specs-user-lifecycle/promotion-codes/promotion-codes'
import type { GetReferralApiResponse } from '@backmarket/http-api/src/api-specs-user-lifecycle/promotion-codes/promotion-codes.types'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'

type GetReferralResponse =
  | (GetReferralApiResponse & {
      hasPromoCode: true
    })
  | {
      hasPromoCode: false
    }
export function useReferAFriend() {
  return useAsyncData<GetReferralResponse>(async () => {
    try {
      const apiResponse = await $httpFetch(getReferral)

      return { ...apiResponse, hasPromoCode: !!apiResponse.referralCode }
    } catch (_err) {
      const err = _err as HttpApiError

      // if the API returns a 404 error, it means that the user does not have a promo code
      // we return a valid payload with hasPromoCode set to false
      if (err.status === 404) return { hasPromoCode: false }

      throw err
    }
  })
}
