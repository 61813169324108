export default {
  titleAboutToGenerate: {
    id: 'referral_block_modal_generate_title_about_to_generate',
    defaultMessage: 'Are you sure you want to use it now?',
  },
  titleAfterGenerate: {
    id: 'referral_block_modal_generate_title_after_generate',
    defaultMessage: 'You’re about to save money! 🎉',
  },
  aboutToGenerate: {
    id: 'referral_block_modal_generate_about_to',
    defaultMessage:
      'You are about to generate a promo code of {highlight, html}. Only one promo code can be used per order.',
  },
  aboutToGenerateAmount: {
    id: 'referral_block_modal_generate_about_to_amount',
    defaultMessage: '{amount}',
  },
  afterGenerate: {
    id: 'referral_block_modal_generate_after',
    defaultMessage:
      'Copy your code below and paste it when you’ll buy. Don’t worry, we also sent you an email in case and added it in your page you want to use it later.',
  },
  generatePromoCode: {
    id: 'referral_block_modal_generate',
    defaultMessage: 'Generate promo code',
  },
  cancel: {
    id: 'referral_block_modal_cancel',
    defaultMessage: 'Cancel',
  },

  copyInputLabel: {
    id: 'referral_block_modal_copy_input_label',
    defaultMessage: 'Promo code',
  },
  buttonCopy: {
    id: 'dashboard_refer_friend_copyclipboard_button_label',
    defaultMessage: 'Copy',
  },
  toastCopyTitle: {
    id: 'dashboard_refer_friend_copyclipboard_toast_title',
    defaultMessage: 'Hop',
  },
  toastCopyText: {
    id: 'dashboard_refer_friend_copyclipboard_toast_text',
    defaultMessage: 'Copied to the clipboard',
  },
  toastCopyCloseAlternativeText: {
    id: 'dashboard_refer_friend_copyclipboard_toast_close_alternative_text',
    defaultMessage: 'Close',
  },

  toastErrorTitle: {
    id: 'referral_block_modal_generate_error_toast_title',
    defaultMessage: 'Oops, something went wrong.',
  },
  toastErrorMessage: {
    id: 'referral_block_modal_generate_error_toast_message',
    defaultMessage: 'Please try again.',
  },
  toastClose: {
    id: 'toast_close_alternative_text',
    defaultMessage: 'Close',
  },
} as const
