<template>
  <div class="flex w-full flex-col gap-36 md:flex-row md:gap-40">
    <div
      class="bg-static-info-mid text-static-default-hi rounded-lg grow px-20 pb-44 pt-32 md:mt-0"
    >
      <RevIllustration
        alt=""
        class="mx-auto mb-24 block md:mb-44"
        :height="176"
        src="/img/refer-a-friend/referral-code-available.png"
        :width="278"
      />

      <div class="mx-auto mb-28 text-center md:mb-24">
        <h1
          v-for="words in sentences"
          :key="words"
          class="heading-1 md:punchline"
        >
          {{ words }}
        </h1>
      </div>

      <div class="flex flex-col gap-16">
        <CopyPromoCodeToClipboard
          :input-label="i18n(translations.inputCopy)"
          :promotion-code="referralCode"
        />

        <ShareReferralCodeButton :amount="repartition.referee" :referral-code />

        <div class="text-center">
          <RevLink
            target="_blank"
            :to="{
              name: CMS.LEGAL_PAGE,
              params: {
                pageName: 'refer-a-friend',
                locale,
              },
            }"
          >
            <span class="body-1-bold underline">
              {{ i18n(translations.referFriendLegalLink) }}
            </span>
          </RevLink>
        </div>
      </div>
    </div>

    <ReferralInfo
      :credit
      :my-promo-codes
      :pending-count="myReferrals.pendingCount"
      :referred-count="myReferrals.referredCount"
      @promo-code-generated="() => emits('promo-code-generated')"
    />
  </div>
</template>

<script lang="ts" setup>
import type { GetReferralApiResponse } from '@backmarket/http-api/src/api-specs-user-lifecycle/promotion-codes/promotion-codes.types'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useI18nLocale } from '@backmarket/nuxt-module-i18n/useI18nLocale'
import { RevIllustration } from '@ds/components/Illustration'
import { RevLink } from '@ds/components/Link'

import { CMS } from '~/scopes/cms/routes-names'

import translations from '../ReferAFriend.translations'

import CopyPromoCodeToClipboard from './CopyPromoCodeToClipboard.vue'
import ReferralInfo from './ReferralInfo.vue'
import ShareReferralCodeButton from './ShareReferralCodeButton.vue'

defineProps<GetReferralApiResponse>()

const emits = defineEmits<{
  (e: 'promo-code-generated'): void
}>()
const i18n = useI18n()
const locale = useI18nLocale()

const splitPunchlineInSentences = () => {
  const punchline = i18n(translations.punchline)
  const sentences = i18n(translations.punchline).match(/[^.!?]+[.!?]+/g)

  return sentences || [punchline]
}

const sentences = splitPunchlineInSentences()
</script>
