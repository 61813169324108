export default {
  title: {
    id: 'refer_friend_explanation_block_title',
    defaultMessage: 'How referrals work?',
  },
  titleStep1: {
    id: 'refer_friend_explanation_title_step1',
    defaultMessage: 'Get a code',
  },
  contentStep1: {
    id: 'refer_friend_explanation_block_step1',
    defaultMessage:
      'Complete your first purchase on Back Market, then grab your code.',
  },
  titleStep2: {
    id: 'refer_friend_explanation_title_step2',
    defaultMessage: 'Share the love',
  },
  contentStep2: {
    id: 'refer_friend_explanation_block_step2',
    defaultMessage:
      'Once they’re registered on Back Market, your friend gets $10 and you get $20 in store credit.',
  },
  titleStep3: {
    id: 'refer_friend_explanation_title_step3',
    defaultMessage: 'Activate your code',
  },
  contentStep3: {
    id: 'refer_friend_explanation_block_step3',
    defaultMessage:
      'You can use your store credit 30 days after your friend makes an order on Back Market.',
  },
}
