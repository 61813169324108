<template>
  <RevInfoBlock
    :content="i18n(translations.infoBlockContent)"
    data-test="referrals-limit-reached-banner"
    :dismissable="true"
    :icon="IconWarning"
    :title="i18n(translations.infoBlockTitle)"
    variant="danger"
  />
</template>

<script lang="ts" setup>
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevInfoBlock } from '@ds/components/InfoBlock'
import { IconWarning } from '@ds/icons/IconWarning'

import translations from '../ReferAFriend.translations'

const i18n = useI18n()
</script>
