<template>
  <div>
    <RevDialog name="refer-friend-generate-promo-code-modal" :title>
      <template #body>
        <p v-if="promoCode" class="body-1 mt-6 text-center">
          {{ i18n(translations.afterGenerate) }}
        </p>

        <p v-else class="body-1 my-24 text-center">
          <FormattedMessage :definition="translations.aboutToGenerate">
            <template #highlight>
              <b class="font-weight-body-1-bold">
                {{
                  i18n(translations.aboutToGenerateAmount, {
                    amount: i18n.price(credit),
                  })
                }}
              </b>
            </template>
          </FormattedMessage>
        </p>
      </template>
      <template #footer="{ close }">
        <div v-if="!promoCode" class="flex flex-col gap-8">
          <RevButton
            data-test="generate-promo-code-button"
            full-width="always"
            :loading
            :tracking="{
              zone: REFER_FRIEND_TRACKING.ZONE,
              name: 'step1_generate_promo_code',
            }"
            variant="primary"
            @click="generatePromoCode"
          >
            {{ i18n(translations.generatePromoCode) }}
          </RevButton>
          <RevButton
            full-width="always"
            :tracking="{
              zone: REFER_FRIEND_TRACKING.ZONE,
              name: 'step2_cancel',
            }"
            variant="secondary"
            @click="close"
          >
            {{ i18n(translations.cancel) }}
          </RevButton>
        </div>
        <CopyPromoCodeToClipboard
          v-if="promoCode"
          input-class="w-full"
          :promotion-code="promoCode"
          :tracking-data="{
            name: 'step3_generate_promo_code',
          }"
        />
      </template>
      <template #trigger="{ open }">
        <RevButton
          v-if="hasEnoughCredit"
          data-test="generate-promo-code-modal-button"
          full-width="always"
          variant="primary"
          @click="open"
        >
          {{ i18n(translations.generatePromoCode) }}
        </RevButton>
      </template>
    </RevDialog>
    <RevToast
      :close-alternative-text="i18n(translations.toastClose)"
      icon-src="/img/toast/storm.svg"
      name="generatePromoCodeToast"
      :opened="isToastOpened"
      :title="i18n(translations.toastErrorTitle)"
      to="#toast-wrapper"
      variant="error"
      @close="toggleToast"
    >
      {{ i18n(translations.toastErrorMessage) }}
    </RevToast>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'

import type { MonetaryAmount } from '@backmarket/http-api'
import { postReferralGenerate } from '@backmarket/http-api/src/api-specs-user-lifecycle/promotion-codes/promotion-codes'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import FormattedMessage from '@backmarket/nuxt-module-i18n/FormattedMessage.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevButton } from '@ds/components/Button'
import { RevDialog } from '@ds/components/Dialog'
import { RevToast } from '@ds/components/Toast'
import { useToggle } from '@vueuse/core'

import { REFER_FRIEND_TRACKING } from '../../tracking.constants'
import CopyPromoCodeToClipboard from '../CopyPromoCodeToClipboard.vue'

import translations from './GeneratePromoCode.translations'

const props = defineProps<{
  credit: MonetaryAmount
}>()

const emits = defineEmits<{
  (e: 'promo-code-generated'): void
}>()
const promoCode = ref('')
const i18n = useI18n()

const hasEnoughCredit = computed(() => parseInt(props.credit.amount, 10) > 0)

const title = computed(() =>
  promoCode.value
    ? i18n(translations.titleAfterGenerate)
    : i18n(translations.titleAboutToGenerate),
)

const { trackClick } = useTracking()

const [isToastOpened, toggleToast] = useToggle(false)
const [loading, toggleLoading] = useToggle(false)

async function generatePromoCode() {
  try {
    toggleLoading(true)
    const { promotionCode } = await $httpFetch(postReferralGenerate)
    promoCode.value = promotionCode

    emits('promo-code-generated')
    trackClick({
      zone: REFER_FRIEND_TRACKING.ZONE,
      name: 'step2_generate_promo_code',
    })
  } catch (error) {
    toggleToast(true)
  } finally {
    toggleLoading(false)
  }
}
</script>
