<template>
  <RevCard
    class="px-24 py-20 md:px-56 md:py-32"
    data-test="no-referral-code-block"
  >
    <RevContentBlock
      :button-label="i18n(translations.noReferralCodeCta)"
      button-variant="primary"
      :image-props="{
        alt: '',
        height: 257,
        src: '/img/refer-a-friend/no-referral-code.png',
        width: 464,
      }"
      :title="i18n(translations.noReferralCodeTitle)"
      :to="{
        name: CMS.EVENT,
        params: {
          pageName: 'good-deals',
          locale,
        },
      }"
      @click="handleCtaClick"
    >
      <p class="body-1 mb-16">
        {{ i18n(translations.noReferralCodeDescription1) }}
      </p>
      <p class="body-1 mb-16">
        <span>
          {{ i18n(translations.noReferralCodeDescription2) }}
        </span>
        &nbsp;
        <span>
          {{ i18n(translations.noReferralCodeDescription3) }}
        </span>
      </p>
      <div class="mt-16">
        <RevLink
          target="_blank"
          :to="{
            name: CMS.LEGAL_PAGE,
            params: {
              locale,
              pageName: 'refer-a-friend',
            },
          }"
        >
          <span class="body-1-bold underline">
            {{ i18n(translations.referFriendLegalLink) }}
          </span>
        </RevLink>
      </div>
    </RevContentBlock>
  </RevCard>
</template>

<script lang="ts" setup>
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useI18nLocale } from '@backmarket/nuxt-module-i18n/useI18nLocale'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevCard } from '@ds/components/Card'
import { RevContentBlock } from '@ds/components/ContentBlock'
import { RevLink } from '@ds/components/Link'

import { CMS } from '~/scopes/cms/routes-names'

import translations from '../ReferAFriend.translations'
import { REFER_FRIEND_TRACKING } from '../tracking.constants'

const { trackClick } = useTracking()

const i18n = useI18n()
const locale = useI18nLocale()

const handleCtaClick = () =>
  trackClick({
    page: REFER_FRIEND_TRACKING.PAGE,
    name: 'make_purchase',
    zone: 'no_refer_code',
  })
</script>
