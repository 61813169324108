<template>
  <RevCard
    class="!bg-static-info-mid text-static-default-hi px-12 py-16 md:w-256"
  >
    <div class="px-20">
      <RevList :class="styles.whiteSeparators" :has-external-borders="false">
        <RevListItemStatic>
          <template #label>
            {{ i18n(translations.friendsReferred) }}
          </template>
          <template #description>
            {{ referredCount }}
          </template>
        </RevListItemStatic>

        <RevListItemStatic v-if="hasPendingReferral">
          <template #label>
            {{ i18n(translations.pendingReferral) }}
          </template>
          <template #description>
            {{ pendingCount }}
          </template>
          <template #suffix>
            <RevTooltip position="top">
              <template #trigger="{ show, hide }">
                <IconInfo
                  @focusin="show"
                  @focusout="hide"
                  @mouseleave="hide"
                  @mouseover="show"
                />
              </template>
              {{ i18n(translations.tooltipContent) }}
            </RevTooltip>
          </template>
        </RevListItemStatic>

        <RevListItemStatic
          v-if="hasPromoCodes"
          class="cursor-pointer px-20 hover:bg-static-default-low-hover"
          data-test="open-generated-promo-codes-btn"
          @click="openHistoryModal"
        >
          <template #label>{{ i18n(translations.codesGenerated) }}</template>
          <template #description>
            {{ myPromoCodes.length }}
          </template>
          <template #suffix>
            <IconChevronRight />
          </template>
        </RevListItemStatic>

        <RevListItemStatic data-test="referral-credit">
          <template #label>
            {{ i18n(translations.credit) }}
          </template>
          <template #description>
            {{ i18n.price(credit) }}
          </template>
        </RevListItemStatic>
      </RevList>
    </div>

    <GeneratePromoCodeModalButton
      :credit
      @promo-code-generated="() => emits('promo-code-generated')"
    />

    <PromoCodesHistoryModal
      v-if="hasPromoCodes"
      :generated-promo-codes="myPromoCodes"
      :modal-name="PROMO_CODES_HISTORY_MODAL"
    />
  </RevCard>
</template>

<script lang="ts" setup>
import { computed, useCssModule } from 'vue'

import type { MonetaryAmount } from '@backmarket/http-api/src/api-models/MonetaryAmount'
import type { PromoCode } from '@backmarket/http-api/src/api-specs-user-lifecycle/promotion-codes/promotion-codes.types'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevCard } from '@ds/components/Card'
import { RevList } from '@ds/components/List'
import { RevListItemStatic } from '@ds/components/ListItemStatic'
import { openModal } from '@ds/components/ModalBase'
import { RevTooltip } from '@ds/components/Tooltip'
import { IconChevronRight } from '@ds/icons/IconChevronRight'
import { IconInfo } from '@ds/icons/IconInfo'

import translations from '../ReferAFriend.translations'

import GeneratePromoCodeModalButton from './GeneratePromoCode/GeneratePromoCodeModalButton.vue'
import PromoCodesHistoryModal from './PromoCodesHistoryModal.vue'

const i18n = useI18n()

const props = defineProps<{
  myPromoCodes: PromoCode[]
  credit: MonetaryAmount
  referredCount: number
  pendingCount: number
}>()

const emits = defineEmits<{
  (e: 'promo-code-generated'): void
}>()
const hasPromoCodes = computed(() => props.myPromoCodes?.length > 0)
const hasPendingReferral = computed(() => props.pendingCount > 0)

const PROMO_CODES_HISTORY_MODAL = 'generated-promo-codes-modal'

function openHistoryModal() {
  openModal(PROMO_CODES_HISTORY_MODAL)
}

const styles = useCssModule()
</script>

<style module>
.whiteSeparators {
  li {
    border-color: white !important;
  }
}
</style>
