<template>
  <div class="mx-auto my-12 flex w-full items-end justify-center">
    <RevToast
      :close-alternative-text="i18n(translations.toastCopyCloseAlternativeText)"
      icon-src="/img/toast/rabbit.svg"
      name="TOAST_COPY_CLIPBOARD"
      :opened="isToastOpen"
      :title="i18n(translations.toastCopyTitle)"
      to="#toast-wrapper"
      variant="success"
      @close="closeToast"
    >
      {{ i18n(translations.toastCopyText) }}
    </RevToast>

    <div
      class="flex w-full items-end justify-center gap-12"
      data-test="promo-code-container"
    >
      <RevInputText
        id="copy-clipboard-input"
        class="w-[370px]"
        :class="[styles.clipboardInput, inputClass]"
        data-test="copy-clipboard-input"
        :disabled="true"
        :hasClearButton="false"
        :label="inputLabel || i18n(translations.inputCopy)"
        :model-value="promotionCode"
      />
      <RevButton
        data-test="copy-to-clipboard"
        variant="secondary"
        @click="handleClick"
      >
        {{ i18n(translations.buttonCopy) }}
      </RevButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, useCssModule } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevButton } from '@ds/components/Button'
import { RevInputText } from '@ds/components/InputText'
import { RevToast } from '@ds/components/Toast'

import translations from '../ReferAFriend.translations'
import { REFER_FRIEND_TRACKING } from '../tracking.constants'

export type CopyClipBoardProps = {
  promotionCode: string
  inputLabel?: string
  inputClass?: string
  trackingData?: Record<string, string>
}

const { trackClick } = useTracking()
const props = defineProps<CopyClipBoardProps>()
const isToastOpen = ref(false)

const i18n = useI18n()
const closeToast = () => {
  isToastOpen.value = false
}

const openToast = () => {
  isToastOpen.value = true
}

const copyToClipboard = async () => {
  try {
    await navigator.clipboard.writeText(props.promotionCode)

    return true
  } catch (err) {
    return false
  }
}
const handleClick = async () => {
  if (await copyToClipboard()) {
    openToast()
    trackClick({
      name: 'copy_code',
      zone: REFER_FRIEND_TRACKING.ZONE,
      value: props.promotionCode,
      ...(props.trackingData || {}),
    })
  }
}

const styles = useCssModule()
</script>

<style module>
.clipboardInput {
  input {
    @apply !bg-static-default-low;
  }
}
</style>
