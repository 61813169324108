export default {
  punchline: {
    id: 'dashboard_refer_friend_banner_punchline_1',
    defaultMessage: "Are you a good friend? Help 'em get $10.",
  },
  shareButtonTitle: {
    id: 'dashboard_refer_friend_banner_punchline_share_button_title',
    defaultMessage: 'Refer a friend',
  },
  shareButtonTextStart: {
    id: 'dashboard_refer_friend_banner_punchline_share_button_text_beginning',
    defaultMessage:
      'Say thanks! You now have {amount} to spend on Back Market with this code: ',
  },
  shareButtonTextEnd: {
    id: 'dashboard_refer_friend_banner_punchline_share_button_text_end',
    defaultMessage: 'Please click on the following link to use your promo code',
  },
  shareButtonLabel: {
    id: 'dashboard_refer_friend_banner_punchline_share_button_label',
    defaultMessage: 'Share',
  },
  inputCopy: {
    id: 'dashboard_refer_friend_copyclipboard_input_label',
    defaultMessage: 'Referral code',
  },
  buttonCopy: {
    id: 'dashboard_refer_friend_copyclipboard_button_label',
    defaultMessage: 'Copy',
  },
  toastCopyTitle: {
    id: 'dashboard_refer_friend_copyclipboard_toast_title',
    defaultMessage: 'Hop',
  },
  toastCopyText: {
    id: 'dashboard_refer_friend_copyclipboard_toast_text',
    defaultMessage: 'Copied to the clipboard',
  },
  toastCopyCloseAlternativeText: {
    id: 'dashboard_refer_friend_copyclipboard_toast_close_alternative_text',
    defaultMessage: 'Close',
  },

  referFriendLegalLink: {
    id: 'dashboard_refer_friend_terms_link',
    defaultMessage: 'Read the terms',
  },

  infoBlockTitle: {
    id: 'dashboard_refer_friend_infoblock_title',
    defaultMessage: 'Limit reached',
  },
  infoBlockContent: {
    id: 'dashboard_refer_friend_infoblock_content',
    defaultMessage:
      'Are you an influencer or something? We’re impressed with your popularity, but you’ve already referred 10 friends this month.  No bigs, you can send more pals our way as soon as the calendar page flips. We’re looking forward to meeting more of your squad!',
  },
  friendsReferred: {
    id: 'referral_block_friends_referred',
    defaultMessage: 'Friends referred',
  },
  credit: {
    id: 'referral_block_credit',
    defaultMessage: 'Account credits  ',
  },
  codesGenerated: {
    id: 'referral_block_codes_generated',
    defaultMessage: 'Codes generated',
  },
  pendingReferral: {
    id: 'referral_block_pending_referral',
    defaultMessage: 'Pending referrals',
  },
  tooltipContent: {
    id: 'referral_block_tooltip_content',
    defaultMessage:
      'The referral remains pending during the 30-day return period of your referree.',
  },

  generatedPromoCodesTitle: {
    id: 'dashboard_refer_friend_history_title',
    defaultMessage: 'Generated promo codes',
  },

  statusValid: {
    id: 'dashboard_refer_friend_status_valid',
    defaultMessage: 'Valid until {date}',
  },
  statusUsed: {
    id: 'dashboard_refer_friend_status_used',
    defaultMessage: 'Used the {date}',
  },
  statusExpired: {
    id: 'dashboard_refer_friend_status_expired',
    defaultMessage: 'Expired since {date}',
  },

  copyInputLabel: {
    id: 'dashboard_refer_friend_modal_history_copy_input_label',
    defaultMessage: 'Promo code',
  },

  noReferralCodeSurtitle: {
    id: 'dashboard_refer_friend_banner_pending_surtitle',
    defaultMessage: 'Are you a good friend?',
  },
  noReferralCodeTitle: {
    id: 'dashboard_refer_friend_banner_pending_title',
    defaultMessage: 'Friends help friends get $10',
  },
  noReferralCodeDescription1: {
    id: 'dashboard_refer_friend_banner_pending_description_1',
    defaultMessage:
      'After you make your first purchase on Back Market, you can refer up to 10 pals and save em $10 on their first purchase.',
  },
  noReferralCodeDescription2: {
    id: 'dashboard_refer_friend_banner_pending_description_2',
    defaultMessage:
      'And while being a good friend is its own reward, you also get $20.',
  },
  noReferralCodeDescription3: {
    id: 'dashboard_refer_friend_banner_pending_description_3',
    defaultMessage:
      'So go ahead. Refer your friend, your mom, or your Gen Z cousin whose respect you crave… for cash.',
  },
  noReferralCodeCta: {
    id: 'dashboard_refer_friend_banner_pending_cta',
    defaultMessage: 'Make your first purchase',
  },
} as const
