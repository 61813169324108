<template>
  <RevDrawer
    :name="modalName"
    :title="i18n(translations.generatedPromoCodesTitle)"
    variant="panel"
  >
    <template #body>
      <div class="px-5">
        <RevList>
          <RevListItemStatic
            v-for="{ credit, promoCode, state } in generatedPromoCodes"
            :key="promoCode"
          >
            <template #label>
              {{ i18n.price(credit) }}
            </template>
            <template #description>
              <ClientOnly>
                <span :class="statusDisplay[state.status].className">
                  {{
                    i18n(statusDisplay[state.status].translationKey, {
                      date: getFormattedDate(state.date),
                    })
                  }}
                </span>
              </ClientOnly>
              <CopyPromoCodeToClipboard
                v-if="isCodeValid(state.status)"
                :input-label="i18n(translations.copyInputLabel)"
                :promotion-code="promoCode"
              />
            </template>
          </RevListItemStatic>
        </RevList>
      </div>
    </template>
  </RevDrawer>
</template>

<script lang="ts" setup>
import type { PromoCode } from '@backmarket/http-api/src/api-specs-user-lifecycle/promotion-codes'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { tw } from '@backmarket/utils/string/tw'
import { RevDrawer } from '@ds/components/Drawer'
import { RevList } from '@ds/components/List'
import { RevListItemStatic } from '@ds/components/ListItemStatic'

import translations from '../ReferAFriend.translations'

import CopyPromoCodeToClipboard from './CopyPromoCodeToClipboard.vue'

defineProps<{
  generatedPromoCodes: PromoCode[]
  modalName: string
}>()

const i18n = useI18n()

const PROMO_CODE_STATUS = {
  EXPIRED: 'EXPIRED',
  VALID: 'VALID',
  USED: 'USED',
}

const getFormattedDate = (date: string) =>
  i18n.date(new Date(date), {
    day: 'numeric',
    month: 'short',
    year: '2-digit',
  })

const isCodeValid = (status: string) => status === PROMO_CODE_STATUS.VALID

const statusDisplay = {
  [PROMO_CODE_STATUS.VALID]: {
    translationKey: translations.statusValid,
    className: tw`text-static-brand-mid`,
  },
  [PROMO_CODE_STATUS.USED]: {
    translationKey: translations.statusUsed,
    className: tw`text-action-success-hi`,
  },
  [PROMO_CODE_STATUS.EXPIRED]: {
    translationKey: translations.statusExpired,
    className: tw`text-static-danger-hi`,
  },
}
</script>
